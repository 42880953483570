<template>
  <div>
    <PageHeader
      image="header_11"
      withMobile
      :title="$lang('title')"
      :breadcrumbs="$lang('breadcr')"
    />
    <h3 class = "contact-top-text" > Come on! <br>Take the first step to study English in Ireland! <br>We are here to answer all your questions.</h3>
    <div id="contact-form-container" class="contact-form-padding">
     
      <!-- Inserted provided script -->
      <div role="main" id="contact-us-website-ada3feb76d16e3e0c3cd"></div>
    </div>
  
    <section class="pt-7 pb-10 bg-blue3">
      <div class="container">
        <div class="row">
          <div class="col-12">
            
          </div>

          <div class="col-lg-6">
            <h3> Dublin Campus</h3>
            <CardAddresses
            
              fileName="campus_dublin"
              title="Building 40"
              street=" "
              neighbour="Dominick Street Lower, Rotunda, Dublin 1"
              neighbour2="Dublin 1"
              eircode="D01 P9P4"
              phone="+353 (0) 8783047"
            />
          </div>

          <div class="col-lg-6">
            <h3> Dublin Campus</h3>
            <CardAddresses
              fileName="campus_dublin"
              title="Building 39"
              street=" "
              neighbour="Dominick Street Lower, Rotunda, Dublin 1"
              neighbour2="Dublin 1"
              eircode="D01 P9P4"
              phone="+353 (0) 8783047"
            />
          </div>

          <div class="col-lg-6">
            <h3> Limerick Campus</h3>
            <CardAddresses
              fileName="campus_limerick"
              title="Chapel House"
              street=" "
              neighbour="Chapel Court"
              neighbour2="Limerick"
              eircode="V94 YH6Y"
              phone="+353 (0) 61468571"
            />
          </div>
          
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import CardAddresses from "@/components/CardAddresses";
export default {
  components: {
    PageHeader,
    CardAddresses,
  },
  mounted() {
    const script = document.createElement("script");
    script.src = "https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js";
    script.onload = () => {
      /* eslint-disable no-undef */
      new RDStationForms('contact-us-website-ada3feb76d16e3e0c3cd', '').createForm();
      /* eslint-enable no-undef */
    };
    document.getElementById('contact-form-container').appendChild(script);
  }
};
</script>

<style>
.contact-form-padding {
  margin-top: 20px;
  /* padding-top: 50px; */
  /* width: 100%; */
  align-items: center;
  position:relative;
  align-content: center ;
  justify-content: center;
  margin-bottom: 20px;
  height: 100vh; /* Full viewport height to center vertically */
  display: flex;
}
</style>
